<template>
  <v-container id="ordertax" fluid tag="section">
    <v-snackbar v-model="updateOrderTax" :timeout="4000" top>
      <span>Tax update successfully</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="primary"
          text
          v-bind="attrs"
          @click="updateOrderTax = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <!-- <v-col cols="6" md="6"> -->
    <v-card>
      <v-card-title> <h3>Tax</h3></v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-form ref="OrderTax">
              <v-container class="py-2">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      type="number"
                      value="0.00"
                      label="Flat Tax(%)"
                      class="purple-input"
                      @keypress="isPriceKey"
                      v-model="order_tax"
                      :rules="inventoryRule"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="text">
                    <v-btn
                      type="button"
                      color="primary"
                      class="mr-0 float-right"
                      @click="submitOrderTax"
                      :loading="loadingOrderTax"
                      >Update</v-btn
                    >
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- </v-col> -->
  </v-container>
</template>

<script>
export default {
  name: "OrderTax",
  data() {
    return {
      order_tax: "",
      OrderTaxID: "",
      loadingOrderTax: false,
      updateOrderTax: false,
      inventoryRule: [(v) => !!v || "Tax is required"],
    };
  },
  methods: {
    isPriceKey(event) {
      const reg = /^-?\d*(\.\d{0,2})?$/;
      let input = event.target.value + String.fromCharCode(event.charCode);

      if (!reg.test(input)) {
        event.preventDefault();
      }
    },
    getOrderTax() {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "setting/edit/order_tax")
        .then((response) => {
          if (response.status == 200) {
            this.OrderTaxID = response.data.setting._id;
            this.order_tax = response.data.setting.value;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    submitOrderTax() {
      if (this.$refs.OrderTax.validate()) {
        this.loadingOrderTax = true;
        let data = {
          id: this.OrderTaxID,
          type: "order_tax",
          value: this.order_tax,
        };
        axios
          .post(process.env.VUE_APP_API_BASE_URL + "setting/update", data)
          .then((response) => {
            if (response.status == 200) {
              this.loadingOrderTax = false;
              this.updateOrderTax = true;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  mounted() {
    this.getOrderTax();
  },
};
</script>

<style>
</style>